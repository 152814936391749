import React from 'react';
import PropTypes from 'prop-types';
import RHelmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const Helmet = ({ lang }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );
  return (
    <RHelmet htmlAttributes={{ lang }} title={site.siteMetadata.title}>
      <meta name="viewport" content="width=360, initial-scale=1" />
      <link
        href="https://fonts.googleapis.com/css?family=Ubuntu&display=swap"
        rel="stylesheet"
      />
      <meta name="format-detection" content="telephone=no" />
    </RHelmet>
  );
};

Helmet.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
};

Helmet.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default Helmet;
